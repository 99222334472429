import { Rentvisie, VehicleClass } from "@bakkie/ratality"
import { useState, useEffect } from "react"
import Modal from "react-bootstrap/Modal"
import LpForm from "../reservation/lp-form"
import PriceTable from "../shared/price-table"
import Skeleton from "../shared/skeletons"
import ImageCarousel from "./image-carousel"

const VehicleClassComponent = ({
  fallbackId,
  queryId,
  queryBranch,
  fallbackBranch,
}) => {
  const useId = queryId ? queryId : fallbackId
  const branchId = queryBranch ? parseInt(queryBranch) : fallbackBranch
  const [loading, setLoading] = useState(true)
  const [vehicleClass, setVehicleClass] = useState<VehicleClass | undefined>(
    undefined
  )
  const [show, setShow] = useState(false)
  const [error, setError] = useState(undefined)
  const [vehicleClassId, setVehicleClassId] = useState(useId)

  let includeVat = "no"
  if (typeof window !== "undefined") {
    includeVat = localStorage.getItem("includeVat")
  }

  useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: "https://api.rentvisie.com/api",
      client: "bertjonk",
      useGuestToken: true,
    })
    Rentvisie.getVehicleClass(vehicleClassId)
      .then(setVehicleClass)
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!vehicleClass) return // Prevent running on initial render

    const vehicleClassDescription =
      vehicleClass?.description || "Unknown Vehicle"
    const marketingPrice = vehicleClass?.marketingPrices?.[0]?.value || 0
    const priceExclVat = Math.round((marketingPrice / 1.21) * 100) / 100
    const itemListName = `staticList_${
      vehicleClass?.category?.description || "Unknown Category"
    }`
    const itemId = vehicleClass?.code || "Unknown Code"
    const itemCategory =
      vehicleClass?.category?.description || "Unknown Category"

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: {
          item_name: vehicleClassDescription,
          price: marketingPrice,
          price_excl_vat: priceExclVat,
          quantity: 1,
          item_list_name: itemListName,
          item_id: itemId,
          item_category: itemCategory,
        },
      },
    })
  }, [vehicleClass]) // Add vehicleClass as a dependency

  if (loading) {
    return (
      <div className="container">
        <Skeleton length={1} />
      </div>
    )
  }
  if (error) {
    return (
      <div className="container">
        <p>{error.message}</p>
      </div>
    )
  }

  const getLocation = id => {
    const locationArray = Settings.locations.filter(
      location => location.branchId === id
    )
    return locationArray[0].value
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div className="container">
      <div className="vehicle-wrapper" style={{ alignItems: "center" }}>
        <ImageCarousel images={vehicleClass.images} />
        <div className="vehicle-select__content">
          <h3 className="bold">
            {vehicleClass.labelMarkup
              ? vehicleClass.labelMarkup.replace(/(<([^>]+)>)/gi, "")
              : vehicleClass.description}
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: vehicleClass.descriptionMarkup }}
          ></div>
          <div className="features">
            {vehicleClass.tags &&
              vehicleClass.tags.slice(0, 3).map(feature => (
                <div className="feature" key={vehicleClass.vehicleClassId}>
                  <div className="feature__icon">o</div>
                  <div className="feature__text">{feature.tagDescription}</div>
                </div>
              ))}
          </div>
          <div className="vehicle-select__prices">
            <p className="price-mileage__mileage"></p>
            <div className="vehicle-select__price">
              <p className="price-mileage__price">
                Vanaf {"€ "}
                {vehicleClass.marketingPrices
                  ? includeVat === "yes"
                    ? vehicleClass.marketingPrices[0].value
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                    : Math.round(vehicleClass.marketingPrices[0].value / 1.21)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")
                  : "0"}
                <span style={{ fontSize: 12, fontStyle: "italic" }}>
                  {includeVat === "yes" ? " incl. BTW" : " excl. BTW"}
                </span>
              </p>
            </div>
          </div>
          <div className="vehicle-select__buttons">
            <div></div>
            <div>
              <button className="button-primary" onClick={handleShow}>
                Huur direct
              </button>
            </div>
          </div>
          <Modal show={show} dialogClassName="modal-90w" onHide={handleClose}>
            <Modal.Header closeButton>
              <div></div>
            </Modal.Header>
            <Modal.Body style={{ padding: 36 }}>
              <div></div>
              <div></div>
              <LpForm
                category={vehicleClass.category.categoryId}
                vehicleClassId={vehicleClassId}
                branchId={branchId}
                locationId={() => getLocation(branchId)}
              ></LpForm>
              <div
                className="cta-modal__selected-vehicle"
                style={{ paddingBottom: 24 }}
              >
                <div>
                  <h3>
                    {vehicleClass.labelMarkup
                      ? vehicleClass.labelMarkup?.replace(/(<([^>]+)>)/gi, "")
                      : vehicleClass.description}
                  </h3>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: vehicleClass.descriptionMarkup,
                    }}
                  ></span>
                </div>
                <img
                  src={
                    vehicleClass.images.length
                      ? vehicleClass.images[0].imageUrl
                      : ""
                  }
                  alt={
                    vehicleClass.images.length
                      ? vehicleClass.images[0].fileName
                      : ""
                  }
                  className="img-fluid"
                ></img>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      {/* <PriceTable vehicleClass={vehicleClass} /> */}
    </div>
  )
}

export default VehicleClassComponent
