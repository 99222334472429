import * as React from "react"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { setShow } from "../../redux/modalSlice"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

export default function OpeningHoursDialog() {
    const dispatch = useDispatch();
  const { show } = useSelector((state: RootState) => state.modal);

  const handleClose = () => {
    dispatch(setShow(false))
  }

  if (!show) return null;

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <p style={{ margin: 0 }}>
          Openingstijden
        </p>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <ul>
          <li>Maandag t/m vrijdag: 07:30 tm 17:30</li>
          <li>Zaterdag: 08:00 tm 17:00 (Purmerend en Zwaag)</li>
          <li>Zaterdag: 08:00 tm 12:30 (Oude Meer)</li>
          <li>Zondag: gesloten</li>
        </ul>
      </DialogContent>
    </BootstrapDialog>
  )
}
